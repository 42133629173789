@import url('https://rsms.me/inter/inter.css');

@tailwind base;

@tailwind components;

@tailwind utilities;

html {
  background-color: #FFF7ED;
}